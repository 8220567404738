import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Slice } from '../components/Slice';
import { HeroSearch, Layout } from '../components';

const IS_BROWSER = typeof window !== 'undefined';

const stripTitleContainingTags = titleHtml => {
  if (!titleHtml) return '';
  return titleHtml.replace('<h2>', '').replace('</h2>', '');
};

const Index = ({ location, data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { prismicHome } = data;
  const { data: pageData } = prismicHome;
  const {
    body: sliceData = [],
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
    illustration,
    search_title: searchTitle,
    search_subtitle: searchSubtitle,
  } = pageData;
  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.localFile && ogImage.localFile.childImageSharp.fixed.src,
    schema: schema.text,
  };

  return (
    <Layout location={location} seo={seo}>
      <HeroSearch
        title={stripTitleContainingTags(searchTitle.html)}
        subtitle={searchSubtitle.text}
        illustration={illustration}
      />
      {sliceData.map(slice => (
        <Slice key={slice.id} data={slice} />
      ))}
    </Layout>
  );
};

export default Index;

export const indexQuery = graphql`
  query indexQuery {
    prismicHome {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        illustration {
          url
        }
        search_subtitle {
          text
        }
        search_title {
          text
          html
        }
        body {
          ...AreasCoveredHomeFragment
          ...ColumnsHomeFragment
          ...StatsHomeFragment
          ...ImageTextColumnsHomeFragment
          ...LogosHomeFragment
        }
      }
    }
  }
`;
